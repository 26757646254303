<template>
  <div>
    <ckeditor4
      ref="editor"
      :value="value"
      :config="{ ...ckeditor4.editorConfig, height }"
      :editor-url="ckeditor4.editorUrl"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import CKEditor4 from 'ckeditor4-vue'
import CKEditor4Config from './ckeditor-4-config'

export default {
  name: 'OkEditor',
  components: {
    ckeditor4: CKEditor4.component,
  },
  props: {
    value: {
      type: [String],
      required: false,
      default: '',
    },
    height: {
      type: Number,
      default: 150,
    },
  },
  data() {
    return {
      ckeditor4: {
        editorConfig: CKEditor4Config,
        editorUrl: 'https://cdn.ckeditor.com/4.15.1/full-all/ckeditor.js',
      },
    }
  },
  methods: {
    handleEditorReady(editor) {
      editor.editing.view.change(writer => {
        writer.setStyle(
          'height',
          `${this.height}px`,
          editor.editing.view.document.getRoot(),
        )
      })
    },
  },
}
</script>
