import { API_URL } from '@/constants/config'
import store from '@/store'
import i18n from '@/libs/i18n'

const CKEditor4Config = {
  height: 150,
  language: i18n.locale,
  contentsLanguage: i18n.locale,
  contentsLangDirection: i18n.locale === 'ar' ? 'rtl' : 'ltr',
  filebrowserUploadUrl: `${API_URL}/images/editor?token=${store.getters['auth/token']}`,
  filebrowserUploadMethod: 'xhr',
  toolbar: [
    {
      name: 'basicstyles',
      items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat', 'Source'],
    },
    {
      name: 'paragraph',
      items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language'],
    },
    '|',
    { name: 'links', items: ['Link', 'Unlink'] },
    { name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak'] },
    '|',
    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
    { name: 'colors', items: ['TextColor', 'BGColor'] },
  ],
}

export default CKEditor4Config
